import { BrowserRouter as Router, Route, Redirect, Switch ,Routes} from 'react-router-dom'
import { Container } from "react-bootstrap";
import { Suspense, lazy, useEffect } from "react";
import Loader from "../../assets/loader.gif"
const Dashboard = lazy(() => import("../Dashboard/Dashboard"));
const DashboardBU = lazy(() => import("../Dashboard/DashboardBU"));
const UserMaster = lazy(() => import("../Admin/UserMaster"));
const RoleMaster = lazy(() => import("../Admin/RoleMaster"));
const ItemMaster = lazy(() => import("../Masters/ItemCodeMaster"));
const ProjectMaster = lazy(() => import("../Masters/ProjectMaster"));
const ProjectCostTypeMaster = lazy(() => import("../Masters/ProjectCostTypeMaster"));
const CurrencyExchangeRateMaster = lazy(() => import("../Masters/CurrencyExchangeRateMaster"));
const ProductCostMaster = lazy(() => import("../Masters/ProductCostMaster"));
const SoDetailsMaster = lazy(() => import("../Masters/SoDetailsMaster"));
const SoSummaryMaster = lazy(() => import("../Masters/SoSummaryMaster"));
const BusinessCodeMaster = lazy(() => import("../Masters/BusinessCodeMaster"));
const WorkflowMatrixMaster = lazy(() => import("../Masters/WorkflowMatrixMaster"));
const EstimatedCostMaster = lazy(() => import("../Transaction/EstimatedCostMaster"));
const FinanceApprovalMaster = lazy(()=> import("../Transaction/FinanceApproval"))
const ActualProjectCostMaster = lazy(() => import("../Transaction/ActualProjectCostMaster"));
const SimulatePOCForm = lazy(() => import("../Transaction/SimulatePOCForm"));
const JVGeneration = lazy(() => import("../Transaction/JVGeneration"));
const QuarterlyEstimateConfirmationMaster = lazy(() => import("../Transaction/QuarterlyEstimateConfirmationMaster"))


const userrole=sessionStorage.getItem("Role")

export default function Main(props) {
  useEffect (() => {
    console.log("userrole",userrole);
    
    if(userrole === null) {
      window.location.reload()
    }
  }
) 
  return (
    <Container bsPrefix="main">
      <Routes>

        <Route exact path="/" element={<Suspense fallback={
          <div className="loader-box">
            <img src={Loader} alt="Loading" className="newloader"></img>
          </div>}>{userrole == 'BU'  ? <DashboardBU /> : <Dashboard /> }</Suspense>}></Route>
          
        <Route exact path="/usermaster" element={<Suspense fallback={
          <div className="loader-box">
            <img src={Loader} alt="Loading" className="newloader"></img>
          </div>}><UserMaster /></Suspense>}></Route>
        <Route exact path="/roleauthorization" element={<Suspense fallback={
          <div className="loader-box">
            <img src={Loader} alt="Loading" className="newloader"></img>
          </div>}><RoleMaster /></Suspense>}></Route>
        <Route exact path="/itemmaster" element={<Suspense fallback={
          <div className="loader-box">
            <img src={Loader} alt="Loading" className="newloader"></img>
          </div>}><ItemMaster /></Suspense>}></Route>
        <Route exact path="/projectmaster" element={<Suspense fallback={
          <div className="loader-box">
            <img src={Loader} alt="Loading" className="newloader"></img>
          </div>}><ProjectMaster /></Suspense>}></Route>
        <Route exact path="/projectcosttype" element={<Suspense fallback={
          <div className="loader-box">
            <img src={Loader} alt="Loading" className="newloader"></img>
          </div>}><ProjectCostTypeMaster /></Suspense>}></Route>
        <Route exact path="/currencyexchangerate" element={<Suspense fallback={
          <div className="loader-box">
            <img src={Loader} alt="Loading" className="newloader"></img>
          </div>}><CurrencyExchangeRateMaster /></Suspense>}></Route>
        <Route exact path="/productcost" element={<Suspense fallback={
          <div className="loader-box">
            <img src={Loader} alt="Loading" className="newloader"></img>
          </div>}><ProductCostMaster /></Suspense>}></Route>
        <Route exact path="/sodetails" element={<Suspense fallback={
          <div className="loader-box">
            <img src={Loader} alt="Loading" className="newloader"></img>
          </div>}><SoDetailsMaster /></Suspense>}></Route>
        <Route exact path="/sosummary" element={<Suspense fallback={
          <div className="loader-box">
            <img src={Loader} alt="Loading" className="newloader"></img>
          </div>}><SoSummaryMaster /></Suspense>}></Route>
        <Route exact path="/workflowmatrix" element={<Suspense fallback={
          <div className="loader-box">
            <img src={Loader} alt="Loading" className="newloader"></img>
          </div>}><WorkflowMatrixMaster /></Suspense>}></Route>
        <Route exact path="/businesscode" element={<Suspense fallback={
          <div className="loader-box">
            <img src={Loader} alt="Loading" className="newloader"></img>
          </div>}><BusinessCodeMaster /></Suspense>}></Route>
        <Route exact path="/simulatepoc" element={<Suspense fallback={
          <div className="loader-box">
            <img src={Loader} alt="Loading" className="newloader"></img>
          </div>}><SimulatePOCForm session="simulatepoc" /></Suspense>}></Route>
        <Route exact path="/estimatedcost" element={<Suspense fallback={
          <div className="loader-box">
            <img src={Loader} alt="Loading" className="newloader"></img>
          </div>}><EstimatedCostMaster /></Suspense>}></Route>

          <Route exact path="/financeapproval" element={<Suspense fallback={
          <div className="loader-box">
            <img src={Loader} alt="Loading" className="newloader"></img>
          </div>}><FinanceApprovalMaster /></Suspense>}></Route>

          <Route exact path="/actualprojectcost" element={<Suspense fallback={
          <div className="loader-box">
            <img src={Loader} alt="Loading" className="newloader"></img>
          </div>}><ActualProjectCostMaster /></Suspense>}></Route>
        <Route exact path="/jvgeneration" element={<Suspense fallback={
          <div className="loader-box">
            <img src={Loader} alt="Loading" className="newloader"></img>
          </div>}><JVGeneration session="jvgeneration" /></Suspense>}></Route>
        <Route exact path="/quarterlyestimate" element={<Suspense fallback={
          <div className="loader-box">
            <img src={Loader} alt="Loading" className="newloader"></img>
          </div>}><QuarterlyEstimateConfirmationMaster /></Suspense>}></Route>
      </Routes>
    </Container>
  );

}