import {
  Button,
  Row,
  Col,
} from "react-bootstrap";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../../auth-config";
import React from "react";
import login1 from "../../assets/LoginImage.jpg";
import godrejLogo from "../../assets/godrej_new.svg";


export default function Login(props) {
  const { instance } = useMsal();

  let companydata = props.userCompanyData;
  console.log(" companydata_Login", companydata);

  function handleLogin(instance) {
    instance.loginRedirect(loginRequest).catch((e) => {
      console.error(e);
    });
  }

  
//changes by harshal
  return (

    <div className="login-container">
      <div class="d-flex justify-content-between align-items-center logoHeader">
      <img
                src={godrejLogo}
                alt="godrejLogo"

              />
        <span  class="headerLink">Godrej Enterprises Group</span>
      </div>
      <Row className="place-right">
      {/* changes by bharshal */}
        <Col xs={1} md={8} className="d-none d-md-block d-lg-block" >
           <div className="loginLeftImage">
            <img
              src={login1}
              alt="godrejLeftImage"

            />
          </div> 
        </Col>
        <Col
          xs={12}
          md={4}
        >     
         {/* changes by harshal */}
          <div className="LoginRightImage text-center px-4" style={{marginTop:'-4rem'}}>
            
            <div className="projectname" >
            <h1 className="namelogo"> POC </h1>
            <h5 className="namelogosecond">Revenue Recognition</h5>
            </div>
           
            <div className="mt-3">
              {props.errorMessage && (
                <div className="loginErrorDiv">
                  <div className="loginErrorDivHeader">Login Failed</div>
                  <div className="loginErrorDivBody">{props.errorMessage}</div>
                </div>
              )}
            </div>
            <div className="LoginRightInput mt-5">
              <Button
                className="forgotButton"
                onClick={() => handleLogin(instance)}
              >
                Login
              </Button>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
}
